import React, { useState, useEffect } from 'react';
import './kino.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import CustomTitle from '../global/CustomTitle';
import { postPredictionsKino } from '../../redux/predictions/action';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Loading from '../loading';

const KinoPage = () => {
  const dispatch = useDispatch();
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const predictionsKinoData = useSelector(({ predictions }) => predictions.predictionsKinoData);

  const trans = settingsData.trans;

  const [loading, setLoading] = useState(false);
  const [lastDrawsCount, setLastDrawsCount] = useState(25);
  const [seedCount, setSeedCount] = useState(6);
  const [kinoNumbers, setKinoNumbers] = useState([]);
  const [pastDraws, setPastDraws] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    dispatch(postPredictionsKino({ lastDrawsCount, seedCount }));
  };

  useEffect(() => {
    if (predictionsKinoData != null) {
      setLoading(false);
      setKinoNumbers(predictionsKinoData.kino_numbers);
      setPastDraws(predictionsKinoData.pastDraws || []);
    }
  }, [predictionsKinoData]);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <CustomTitle>{trans.predictions_kino_title}</CustomTitle>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
              <Grid item xs={12} md={3}>
                <TextField
                  label={trans.kino_last_draws_count}
                  type="number"
                  value={lastDrawsCount}
                  onChange={(e) => setLastDrawsCount(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label={trans.kino_seed_count}
                  type="number"
                  value={seedCount}
                  onChange={(e) => setSeedCount(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  onClick={getData}
                  disabled={loading}
                  fullWidth
                >
                  {loading ? trans.kino_reload_data_loading : trans.kino_reload_data}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>{trans.kino_predictions_draw_title}</Typography>
            {kinoNumbers.length > 0 ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
                {kinoNumbers.map((number, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      width: 50,
                      height: 50,
                      borderRadius: '50%',
                      backgroundColor: '#ffb74d',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                    }}
                  >
                    {number}
                  </Box>
                ))}
              </Box>
            ) : loading ? <Loading /> : (
              <div>{trans.kino_reload_data_empty}</div>
            )}
          </Paper>
        </Grid>

        {/* New Section for Past Draws */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>{trans.kino_past_draws_title}</Typography>
            {pastDraws.length > 0 ? (
              <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                {pastDraws.map((draw) => (
                  <Box key={draw.drawId} sx={{ mb: 2, borderBottom: '1px solid #ccc', pb: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      Draw ID: {draw.drawId}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                      {draw.winningNumbers.map((number, idx) => (
                        <Box
                          key={idx}
                          sx={{
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            backgroundColor: '#e0e0e0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#000',
                            fontWeight: 'bold',
                          }}
                        >
                          {number}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="body1">{trans.kino_no_past_draws}</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default KinoPage;
