import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postRecommendations } from '../../redux/cryptos/action';
import {
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import Loading from '../loading';
import CustomTitle from '../global/CustomTitle';

const CryptoPage = () => {
  const dispatch = useDispatch();
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const cryptosRecommendationsData = useSelector(({ cryptos }) => cryptos.cryptosRecomentationsData);

  const trans = settingsData?.trans;

  const [loading, setLoading] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [buyBudget, setBuyBudget] = useState(20);
  const [sellUnits, setSellUnits] = useState(20);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    dispatch(postRecommendations({}));
  };

  useEffect(() => {
    if (cryptosRecommendationsData) {
      setLoading(false);
      setRecommendations(cryptosRecommendationsData);
    }
  }, [cryptosRecommendationsData]);

  // Get buy recommendations within budget
  const getBuyRecommendations = () => {
    let remainingBudget = buyBudget;
    return recommendations.filter((rec) => {
      if (rec.action === 'BUY' && rec.price <= remainingBudget) {
        remainingBudget -= rec.price;
        return true;
      }
      return false;
    });
  };

  const buyRecommendations = getBuyRecommendations();
  const sellRecommendations = recommendations.filter((rec) => rec.action === 'SELL');

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <CustomTitle>{trans.cryptos_kino_title}</CustomTitle>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label={trans.buy_budget_label}
                  type="number"
                  value={buyBudget}
                  onChange={(e) => setBuyBudget(Number(e.target.value))}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={trans.sell_units_label}
                  type="number"
                  value={sellUnits}
                  onChange={(e) => setSellUnits(Number(e.target.value))}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {loading ? (
          <Loading />
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3, borderRadius: 3, boxShadow: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {trans.buy_recommendations_title}
                </Typography>
                <Box>
                  {buyRecommendations.length > 0 ? (
                    buyRecommendations.map((rec, index) => {
                      const maxUnits = Math.floor(buyBudget / rec.price);
                      return (
                        <Box key={index} sx={{ mb: 2 }}>
                          <Typography>
                            <strong>{rec.coin.toUpperCase()}</strong>: {trans.buy_action} at €{rec.price} (Max: {maxUnits} units)
                          </Typography>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography>{trans.no_buy_recommendations}</Typography>
                  )}
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3, borderRadius: 3, boxShadow: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {trans.sell_recommendations_title}
                </Typography>
                <Box>
                  {sellRecommendations.length > 0 ? (
                    sellRecommendations.map((rec, index) => (
                      <Box key={index} sx={{ mb: 2 }}>
                        <Typography>
                          <strong>{rec.coin.toUpperCase()}</strong>: {trans.sell_action} {sellUnits} units at €{rec.price} each (Total: €{(rec.price * sellUnits).toFixed(2)})
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <Typography>{trans.no_sell_recommendations}</Typography>
                  )}
                </Box>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default CryptoPage;
