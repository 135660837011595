import { POST_CRYPTOS_RECOMENDATIONS } from "../actionTypes";

const INIT_STATE = {
  cryptosRecomentationsData: null
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_CRYPTOS_RECOMENDATIONS:
      return {
        ...state,
        cryptosRecomentationsData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
