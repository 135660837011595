import {combineReducers} from 'redux';


import settings from './settings/reducer';
import auth from './auth/reducer';
import predictions from './predictions/reducer';
import cryptos from './cryptos/reducer';


export default combineReducers({
    settings: settings,
    auth: auth,
    predictions: predictions,
    cryptos: cryptos
  });
