import {
  POST_CRYPTOS_RECOMENDATIONS
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
  
  export const postRecommendations = (postData) => async dispatch => {
      //Get user locale of the machine he is on
    const userLocale = General.getLocale();

    postData.langLoc = userLocale;
    postData.isAdmin = false;
  

    const response = await axios.post(`cryptos/recommendations`,postData);
    if (DEBUG_MODE){
      console.log(response.data);
    }
  
    dispatch({type: POST_CRYPTOS_RECOMENDATIONS, payload: response.data});
  };